import {
  BaseViewModel,
  getTimezoneForSiteSelector
} from '../../../../../common';
import { getLogger } from 'aurelia-logging';
import { customElement, bindable, autoinject } from 'aurelia-framework';
import {
  ITankUsageWithRefill,
  ISiteChannel,
  TankUsageDuration,
  IUnit
} from '../../../../../../interfaces';
import {
  TankService,
  ChannelService,
  UnitService
} from '../../../../../../services';
import { IAsyncEntity } from '../../../../../../types';
import {
  ensureDate,
  isNone,
  emptyArray,
  isSameDay,
  orderByPredicate
} from '../../../../../../utility';
import { rootState } from '../../../../../../reducers';
import { tankUsageFilterChanged } from '../../../../../../actions';
import './tankusage.css';

interface ITankUsageState {
  tankId: number;
  tankUsageBars: IAsyncEntity<ITankUsageBar[]>;
  tankUsage: IAsyncEntity<ITankUsageWithRefill[]>;
  usageFilter: TankUsageDuration;
  unit: IUnit | undefined;
  timezone?: string;
}

interface ITankUsageBar {
  date: string;
  positive: number;
  negative: number;
}

const getUsageDateFromFilter = (
  usageFilter: TankUsageDuration,
  siteChannel: ISiteChannel | undefined
): Date | undefined => {
  switch (usageFilter) {
    case TankUsageDuration.LastRefill:
      return siteChannel &&
        siteChannel.tankDetails &&
        siteChannel.tankDetails.lastFilled
        ? ensureDate(siteChannel.tankDetails.lastFilled)
        : undefined;
    case TankUsageDuration.LastSevenDays:
      return new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 7);
    case TankUsageDuration.LastThirtyOneDays:
      return new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 31);
    default:
      return undefined;
  }
};

@autoinject()
@customElement('tank-usage')
export class TankUsage extends BaseViewModel<ITankUsageState> {
  @bindable({ changeHandler: 'reattachMapState' }) tankId: number | undefined;
  @bindable({ changeHandler: 'reattachMapState' }) siteId: number | undefined;
  duration = TankUsageDuration;

  constructor(
    private tankService: TankService,
    private channelService: ChannelService,
    private unitService: UnitService
  ) {
    super(getLogger('TankUsage'));
  }

  bind() {
    this.reattachMapState();
  }

  isNone = isNone;

  reattachMapState() {
    if (!this.tankId || !this.siteId) return;
    this.attachMapState(this.mapState(this.tankId, this.siteId));
  }

  filterUsage(duration: TankUsageDuration) {
    this.dispatch(tankUsageFilterChanged(duration));
  }

  mapState = (tankId: number, siteId: number) => (
    state: rootState
  ): ITankUsageState => {
    const asyncChannel = this.channelService
      .getSiteChannelsForSite(siteId)
      .map(channels =>
        channels.find(c => !!c.tankDetails && c.tankDetails.tankId === tankId)
      );
    const unit = this.unitService
      .fetchUnits()
      .map2(asyncChannel, (units, channel) =>
        units.find(u => !!channel && channel.unitId === u.unitId)
      );
    const asyncTankUsage = this.tankService
      .getTankUsage(tankId)
      .map2(asyncChannel, (tankUsage, channel) => {
        if (isNone(channel)) return emptyArray;

        const usageDate = getUsageDateFromFilter(
          state.sitedetailsTanks.usageFilter,
          channel
        );
        if (isNone(usageDate)) return emptyArray;
        const usageWithRefills = tankUsage.map(u => ({
          refill: isSameDay(channel.tankDetails!.lastFilled, u.date),
          date: u.date,
          usage: u.usage
        }));

        return usageDate
          ? usageWithRefills.filter(
              usage => ensureDate(usage.date) >= usageDate
            )
          : usageWithRefills;
      });

    return {
      tankId,
      tankUsageBars: asyncTankUsage
        .map(tankUsage => {
          const max = Math.max.apply(
            null,
            tankUsage.map(usage => Math.abs(usage.usage || 0))
          );
          return tankUsage.map<ITankUsageBar>(usage =>
            (usage.usage || 0) > 0
              ? {
                  date: usage.date,
                  positive: ((usage.usage || 0) / max) * 50,
                  negative: 0
                }
              : {
                  date: usage.date,
                  positive: 0,
                  negative: (Math.abs(usage.usage || 0) / max) * 50
                }
          );
        })
        .getAsyncEntity(),
      tankUsage: asyncTankUsage
        .map(usage => orderByPredicate(usage, u => u.date, 'desc'))
        .getAsyncEntity(),
      usageFilter: state.sitedetailsTanks.usageFilter,
      unit: unit.getEntityOrUndefined(),
      timezone: getTimezoneForSiteSelector(state)
    };
  };
}

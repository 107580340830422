import { I18N } from 'aurelia-i18n';
import { autoinject, computedFrom } from 'aurelia-framework';
import { rootState } from '../../../../../reducers';
import {
  ensureDate,
  isNone,
  getNewId,
  exitMaybe,
  getEntityOrDefault,
  toObject,
  emptyArray,
  entityIsOffline,
  calculateOutsideThreshold,
  channelHasNoConsumption,
  channelIsInErrorState,
  getAsyncEntity,
  routeIsActive,
  getAsyncEntitiesByAsyncArray,
  userHasFeature,
  channelIsTankChannel,
  modifyDateRange,
  setEndOfDay,
  setBeginningOfDay,
  ensureNumber,
  millisecondsBetweenDates,
  toLocalTimeFromUtc,
} from '../../../../../utility';
import { alarmTypes, IPoint } from '../../../../../models';
import {} from '../../../../../services/historianService';
import {
  ISiteDetailsTank,
  IProduct,
  ILoadingPoint,
  TankHistoryFilter,
  IDateRange,
  ISiteChannel,
  IRequestStateWrapper,
  IVehicle,
  IAlarm,
  AccessLevel,
  ILocalizedDateRange,
  IHistoryItem,
  TankPredictionEnum,
  ISiteTankChannel,
  IHaulier
} from '../../../../../interfaces';
import {
  getTankDetailsActiveTab,
  selectSiteDetailsTanksForCurrentSite
} from '../../../selectors';
import {
  TIME,
  OrderProcess,
  TankTypes,
  features,
  routeNames,
  TankPredictionTypes
} from '../../../../../config';
import { Router } from 'aurelia-router';
import {
  TankService,
  ChannelService,
  ControllerService,
  ProductService,
  HaulierService
} from '../../../../../services';
import { Maybe } from 'tsmonad';
import {
  markTankInformationForEdit,
  cancelTankInformationForEdit,
  toggleExpandMenuOnEntity,
  editSiteChannelTankDetailsProperty,
  toggleCustomHistoryFilter,
  tankHistoryDateRangeChanged,
  editSiteChannelProperty,
  toggleRefillPointEditing
} from '../../../../../actions';
import { menuIsOpenForEntity } from '../../../../../types';
import { getLogger } from 'aurelia-logging';
import { selectVehicles, selectLoadingPoints } from '../../../../common/index';
import { getTimezoneForSiteSelector } from '../../../../common/selectors';
import { LoadingPointService } from '../../../../../services/loadingPointService';
import { getSession } from '../../../../../config/sessionService';
import {
  ISelectedGraphPoint,
  TankTrendChartChannelDetailsFragmentDeclaration
} from '../../../../../components/charts/tanktrendchart/tanktrendchart';
import { GraphQLBaseViewModel } from '../../../../common/GraphQLBaseViewModel';
import gql from 'graphql-tag';
import {
  SiteDetailsTanksDetailsQuery,
  SiteDetailsTanksDetailsQueryVariables
} from '../../../../../../custom_typings/graphql';

interface ISiteDetailsTanksDetailsState {
  tankDetails?: ISiteDetailsTankDetails;
  tanks: ISiteDetailsTank[];
  siteId: number;
  products: IProduct[];
  vehicles: IVehicle[];
  haulierMap: Record<string, IHaulier>;
  loadingPoints: ILoadingPoint[];
  menuOpenForEntity?: menuIsOpenForEntity;
  productsMap: Record<string, IProduct>;
  loadingPointsMap: {};
  orderProcesses: typeof OrderProcess;
  tankPredictionTypes: typeof TankPredictionTypes;
  alarmTypes: {};
  activeAlarmsMap: {};
  tankTypes: typeof TankTypes;
  isMobile: boolean;
  access: {
    editTank: boolean;
    tankUsage: boolean;
  };
  timezone?: string;
  tankDetailsValidationErrors: Record<string, string[]> | string | undefined;
  formIsValid: boolean;
}

interface ISiteDetailsTankDetails {
  tankId: number;
  activeTab?: string;
  tankChannel?: ISiteChannel;
  editInfo?: ISiteChannel;
  editInfoProduct?: IProduct;
  tankHistory?: IPoint[];
  tankHistoryPrediction?: IPoint[];
  tankHistoryFilter: TankHistoryFilter;
  showCustomHistoryDropdown: boolean;
  tankHistoryCustomDateRange: IDateRange;
  tankHistoryIsLoading: boolean;
  tankHistoryStatistics:
    | { tankId: number; dates: ILocalizedDateRange }
    | undefined;
  originalTankWasParked: boolean;
  outsideThreshold: undefined | boolean;
  yMaxValue: number;
  error: undefined | boolean;
  noConsumption: undefined | boolean;
  refillsAndOrders?: IHistoryItem[];
  tankIsOffline: boolean;
  hasAlarm: boolean;
  activeAlarms: IAlarm[];
  isRemote: boolean;
  editRefillPointEnabled: boolean;
}

const getTankHistoryPredictionLineFromLastDatapoint = (
  tankChannel: ISiteTankChannel
): [Date, number] | undefined => {
  const {
    predictionLineFromDate,
    predictionLineFromValue
  } = tankChannel.tankDetails;
  if (isNone(predictionLineFromDate) || isNone(predictionLineFromValue)) return;
  return [ensureDate(predictionLineFromDate), predictionLineFromValue];
};

const getTankHistoryPredictionLineFromLastRefill = (
  tankChannel: ISiteTankChannel,
  timezone?: string
): [Date, number] | undefined => {
  const { lastFilled, valueLastFilled } = tankChannel.tankDetails;
  if (isNone(lastFilled) || isNone(valueLastFilled)) return;
  return [toLocalTimeFromUtc(ensureDate(lastFilled), timezone), valueLastFilled];
};

/**
 * US 6713: Find point on tank history graph (which is a graph of hourly mean values), that is as close as possible to the 
 * refill point from TankChannel (aka "tankDetails"). The refill point is calculated from five minute values, so it does not 
 * always fit perfectly with hourly mean values. Therefore the "snap to tank graph" functionality was requested.
 */
const getTankHistoryPredictionLineFromPointClampedToTankGraph = (
  siteChannel: ISiteTankChannel,
  tankHistoryPoints: IPoint[]
): [Date, number] | undefined => {

  if (isNone(tankHistoryPoints) || tankHistoryPoints.length < 1) {
    return undefined;
  }
  const { lastFilled, valueLastFilled } = siteChannel.tankDetails;
  if (isNone(lastFilled) || isNone(valueLastFilled)) {
    return undefined;
  }
  const lastFilledEpochMs = ensureDate(lastFilled).valueOf();

  // "Reduce" loops throught the points, and keeps track of one object { diffMs, point } (which at any time is the point deemed closest to the refill point, timewise).
  // The initial "closest" is given as the second parameter to "reduce".
  const closestToRefill = (tankHistoryPoints.reduce((previousClosest, currentPoint, index) => {
    const epochDiffMs = Math.abs(ensureDate(currentPoint.ts).valueOf() - lastFilledEpochMs);

    if (epochDiffMs < previousClosest.diffMs) {
      return ({
        diffMs: epochDiffMs,  // found graph point closer to refill in time
        point: currentPoint,
        index
      });
    } else {
      return previousClosest;
    }
  }, 
  {         // initial "closest" (which can be way off):
    diffMs: Math.abs(ensureDate(tankHistoryPoints[0].ts).valueOf() - lastFilledEpochMs),   
    point: tankHistoryPoints[0],
    index: 0
  }));
  
  if (isNone(closestToRefill)) {
    return undefined;
  }

  // #6713 (6783) Sanity check "closest", by looking for higher values after it in time
  // Find five points after "closest" (if possible). Their times do not matter as the graph may show 5 min values or daily values, depending on the current "resolution":
  const pointsCloseByWithinTimeSpan = tankHistoryPoints.slice(closestToRefill.index+1, closestToRefill.index+6);

  // Find the max value point, and use that:
  let descendingSortedArray = [ ...pointsCloseByWithinTimeSpan, closestToRefill.point ];
  descendingSortedArray.sort((a, b) => b.v - a.v);
  const maxPoint = descendingSortedArray[0];
  
  return [ ensureDate(maxPoint.ts), maxPoint.v]
}



/**
 * Returns the trendline for the graph. This is either from the last refill point to reach-minimum or from last sample-time in dataset to reach-minimum.
 */
const getTankHistoryPredictionLine = (
  state: rootState,
  siteChannel: ISiteChannel | undefined,
  timezone?: string
): [IPoint, IPoint] | undefined => {
  if (
    !siteChannel ||
    !siteChannel.tankDetails ||
    !channelIsTankChannel(siteChannel)
  )
    return;

  const { tankHistory } = state.sitedetailsTanks;
  if (!tankHistory || tankHistory.type !== 'done') return;
  const { query, entity } = tankHistory;
  const tankHistoryPoints = entity;

  const { reachEmpty } = siteChannel.tankDetails;
  if (isNone(reachEmpty)) return;

  let fromDateAndValue: [Date, number] | undefined;
  switch (siteChannel.tankDetails.predictionType) {
    case TankPredictionEnum.TankCapacity:
      // #6713: clamp to tank graph replaces the original, which has been moved to another enum switch case, in case someone changes their mind.
      fromDateAndValue = getTankHistoryPredictionLineFromPointClampedToTankGraph(
        siteChannel,
        tankHistoryPoints
      )

      break;
    case TankPredictionEnum.TankUsageVector:
      fromDateAndValue = getTankHistoryPredictionLineFromLastDatapoint(
        siteChannel
      );
      break;
    case TankPredictionEnum.TankCapacityFromFilteredRawValues: 
      // #6713: this was the original way to place the prediction line:
      fromDateAndValue = getTankHistoryPredictionLineFromLastRefill(
        siteChannel,
        timezone
      );
}

  if (!fromDateAndValue) return;
  const [fromDate, fromValue] = fromDateAndValue;
  if (millisecondsBetweenDates(fromDate, new Date()) < TIME.HOURINMS) return;

  const reachEmptyDate = ensureDate(reachEmpty);

  if (query.dates.to < fromDate) return;
  if (query.dates.from > reachEmptyDate) return;

  const slopePerMs =
    -fromValue / (reachEmptyDate.getTime() - fromDate.getTime());

  const trendSlopeZeroCrossingDate = new Date(
    fromDate.getTime() + -fromValue / slopePerMs
  ).toString();

  const fromPoint: IPoint =
    fromDate > query.dates.from
      ? { ts: fromDate.toString(), v: fromValue }
      : {
          ts: query.dates.from.toString(),
          v:
            fromValue +
            (query.dates.from.getTime() - fromDate.getTime()) * slopePerMs
        };

  const toPoint: IPoint =
    reachEmptyDate < query.dates.to
      ? { ts: trendSlopeZeroCrossingDate.toString(), v: 0 }
      : {
          ts: query.dates.to.toString(),
          v:
            fromValue +
            (query.dates.to.getTime() - fromDate.getTime()) * slopePerMs
        };

  return [fromPoint, toPoint];
};

const appendLastSampleTimeToTankHistory = (
  tankHistoryWrapped: Maybe<
    IRequestStateWrapper<
      IPoint[],
      { tankId: number; dates: ILocalizedDateRange }
    >
  >,
  siteChannel: ISiteChannel | undefined
): IPoint[] | undefined => {
  const tankHistory = exitMaybe(tankHistoryWrapped);
  if (isNone(tankHistory)) return;
  if (tankHistory.type !== 'done' || isNone(siteChannel)) return;
  const { lastSample, lastSampleTime } = siteChannel;
  const parsedLastSampleTime = ensureDate(lastSampleTime);
  if (
    tankHistory.query.dates.from > parsedLastSampleTime ||
    tankHistory.query.dates.to < parsedLastSampleTime
  )
    return tankHistory.entity;

  if (tankHistory.entity.find(e => e.ts === lastSampleTime))
    return tankHistory.entity;

  return [...tankHistory.entity, { ts: lastSampleTime, v: lastSample }];
};

const getAsyncTankHistory = (
  state: rootState,
  tankId: number
): Maybe<
  IRequestStateWrapper<IPoint[], { tankId: number; dates: ILocalizedDateRange }>
> => {
  const { tankHistory } = state.sitedetailsTanks;
  if (!tankHistory) return Maybe.nothing();

  return tankHistory.query.tankId !== tankId
    ? Maybe.nothing()
    : Maybe.just(tankHistory);
};

const getYMaxValue = (channel: ISiteChannel | undefined): number => {
  if (isNone(channel)) return 0;
  return Math.max(channel.lastSample, channel.capacity || 0);
};

const mapState = (
  channelService: ChannelService,
  controllerService: ControllerService,
  productService: ProductService,
  haulierService: HaulierService,
  siteChannelId: number,
  tankId: number,
  siteId: number
) => (state: rootState): ISiteDetailsTanksDetailsState => {
  const haulierFetcher = haulierService.getAll();
  const haulierMap = toObject(
    haulierFetcher.getEntityOrDefault(emptyArray),
    h => h.haulierId
  );

  const productsFetcher = productService.getAll();
  const productsMap = toObject(
    productsFetcher.getEntityOrDefault(emptyArray),
    p => p.productId
  );
  const loadingPointsMap = toObject(
    getEntityOrDefault(selectLoadingPoints(state), []),
    lp => lp.loadingPointId
  );
  const tankChannel = channelService
    .getSiteChannelBySiteChannelId(siteChannelId)
    .getEntityOrUndefined();

  const allAlarms = getEntityOrDefault(
    getAsyncEntitiesByAsyncArray(
      getAsyncEntity(state.alarms.alarmsForSites, siteId),
      state.alarms.byId
    ),
    emptyArray
  );
  const activeAlarmsBySiteChannelId = toObject(
    allAlarms.filter(a => a.active && !isNone(a.siteChannelId)),
    a => a.siteChannelId!
  );
  const isRemote =
    controllerService
      .getControllersBySiteId(siteId)
      .map(cs =>
        cs.find(
          c => !!tankChannel && c.controllerId === tankChannel.controllerId
        )
      )
      .map(c => c != null && c.siteId !== siteId)
      .getEntityOrDefault(false) || false;

  const timezone = getTimezoneForSiteSelector(state);
  
  return {
    siteId,
    tanks: selectSiteDetailsTanksForCurrentSite(state),
    activeAlarmsMap: activeAlarmsBySiteChannelId,
    menuOpenForEntity: state.application.menuIsOpenForEntity,
    products: productsFetcher.getEntityOrDefault(emptyArray),
    loadingPoints: getEntityOrDefault(selectLoadingPoints(state), []),
    vehicles: getEntityOrDefault(selectVehicles(state), []),
    haulierMap,
    tankDetails: {
      tankId,
      activeTab: getTankDetailsActiveTab(state).valueOr('graph'),
      editInfo: state.sitedetailsTanks.tankInformationToModify,
      editInfoProduct:
        state.sitedetailsTanks.tankInformationToModify &&
        state.sitedetailsTanks.tankInformationToModify.productId
          ? productsMap[
              state.sitedetailsTanks.tankInformationToModify.productId
            ]
          : undefined,
      tankChannel,
      tankIsOffline: (tankChannel && entityIsOffline(tankChannel)) || false,
      originalTankWasParked: tankChannel ? tankChannel.isParked : false,
      tankHistory: appendLastSampleTimeToTankHistory(
        getAsyncTankHistory(state, tankId),
        tankChannel
      ),
      tankHistoryIsLoading: getAsyncTankHistory(state, tankId)
        .map(a => a.type === 'processing')
        .valueOr(false),
      tankHistoryStatistics:
        state.sitedetailsTanks.tankHistory &&
        state.sitedetailsTanks.tankHistory.query,
      tankHistoryPrediction: getTankHistoryPredictionLine(state, tankChannel, timezone),
      tankHistoryFilter: state.sitedetailsTanks.tankHistoryFilter,
      showCustomHistoryDropdown:
        state.sitedetailsTanks.showCustomHistoryDropdown,
      tankHistoryCustomDateRange: state.sitedetailsTanks
        .tankHistoryCustomDateRange || {
        from: new Date(),
        to: new Date(new Date().getTime() - 1000 * 60 * 24 * 7)
      },
      outsideThreshold: tankChannel && calculateOutsideThreshold(tankChannel),
      noConsumption:
        tankChannel &&
        channelIsTankChannel(tankChannel) &&
        channelHasNoConsumption(tankChannel),
      error: tankChannel && channelIsInErrorState(tankChannel),
      yMaxValue: getYMaxValue(tankChannel),
      hasAlarm: tankChannel
        ? activeAlarmsBySiteChannelId[tankChannel.siteChannelId] !== undefined
        : false,
      activeAlarms: tankChannel
        ? allAlarms.filter(
            a => a.active && a.siteChannelId === tankChannel.siteChannelId
          )
        : emptyArray,
      isRemote,
      editRefillPointEnabled: state.sitedetailsTanks.editRefillPointEnabled,
    },
    loadingPointsMap,
    productsMap,
    orderProcesses: OrderProcess,
    alarmTypes,
    tankTypes: TankTypes,
    isMobile: state.device.screenSize === 'mobile',
    tankPredictionTypes: TankPredictionTypes,
    access: {
      editTank: userHasFeature(
        getSession(),
        features.siteTanks,
        AccessLevel.Write
      ),
      tankUsage: userHasFeature(
        getSession(),
        features.detailedTankUsage,
        AccessLevel.Read
      )
    },
    timezone,
    tankDetailsValidationErrors:
      state.sitedetailsTanks.tankChannelValidationErrors,
    formIsValid:
      state.sitedetailsTanks.tankChannelValidationErrors === undefined
  };
};

const shouldMapState = (state: rootState): boolean =>
  routeIsActive(state.router.currentRoute, routeNames.sitedetailsTanksDetails);

@autoinject()
export class SiteDetailsTanksDetails extends GraphQLBaseViewModel<
  ISiteDetailsTanksDetailsState,
  SiteDetailsTanksDetailsQuery,
  SiteDetailsTanksDetailsQueryVariables
> {
  historyFilter = TankHistoryFilter;

  public menuForLoadingPoint = getNewId();
  public orderProcessMenu = getNewId();

  constructor(
    private i18n: I18N,
    private router: Router,
    private tankService: TankService,
    private channelService: ChannelService,
    private loadingPointService: LoadingPointService,
    private controllerService: ControllerService,
    private productService: ProductService,
    private haulierService: HaulierService
  ) {
    super(getLogger('SiteDetailsTanks'));
  }

  query = gql`
    query SiteDetailsTanksDetailsQuery($siteChannelId: Int!, $siteId: Int!) {
      siteChannel(siteChannelId: $siteChannelId) {
        alias
        maximum
        minimum
        capacity
        ...TankTrendChartChannelDetailsFragment
        product {
          name
          productId
          languageKey
        }
        tankDetails {
          vehicle {
            codeAndName
          }
          loadingPointId
        }
      }
      site(siteId: $siteId) {
        siteId
        soldTo
      }
    }
    ${TankTrendChartChannelDetailsFragmentDeclaration}
  `;

  siteId: number;
  siteChannelId: number;

  activate({ siteChannelId, id }: { siteChannelId: string; id: string }) {    
    this.loadingPointService.fetchLoadingPointsAsync();
    const siteChannelIdParsed = ensureNumber(siteChannelId);
    const siteId = ensureNumber(id);

    this.siteId = siteId;
    this.siteChannelId = siteChannelIdParsed;

    this.variables = {
      siteChannelId: siteChannelIdParsed,
      siteId
    };
    this.channelService
      .getSiteChannelsForSiteAsync(siteId)
      .then(siteChannels => {
        const tank = siteChannels.find(
          sc => sc.siteChannelId === siteChannelIdParsed
        );
        if (!tank || !tank.tankDetails) return;
        this.attachMapState(
          mapState(
            this.channelService,
            this.controllerService,
            this.productService,
            this.haulierService,
            siteChannelIdParsed,
            tank.tankDetails.tankId,
            siteId
          ),
          shouldMapState
        );
        this.activateWithTankId(tank.tankDetails.tankId);
      });
  }

  activateWithTankId(tankId: number) {
    const { siteId, tankDetails, tanks } = this.state;
    if (isNone(tankDetails)) return;
    const { tankHistory } = tankDetails;
    if (!siteId) return;

    const currentDate = new Date();

    const currentTank = tanks.find(t => t.tankId === tankId);

    if (isNone(tankHistory) && currentTank)
      if(this.tankReachMinIsOverOneYearIntoTheFuture(currentTank.reachMinimum)){
        const oneWeekAgo = new Date();
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7)
      
        const oneWeekFromNow = new Date();
        oneWeekFromNow.setDate(oneWeekFromNow.getDate() + 7)

        this.tankService.getTankHistoryFromDateRange(
          siteId,
          tankId,
          modifyDateRange(
            setBeginningOfDay(oneWeekAgo),
            setEndOfDay(oneWeekFromNow),
            TIME.DAYINMS
          ),
          TankHistoryFilter.custom,
          true,
          this.state.timezone
        );
      }
      else if (
        this.tankHistoryCanShowReachMin(
          currentTank.reachMinimum,
          currentTank.lastFilled
        ) &&
        currentTank.lastFilled &&
        currentTank.reachMinimum
      )
        this.tankService.getTankHistoryFromDateRange(
          siteId,
          tankId,
          modifyDateRange(
            setBeginningOfDay(currentTank.lastFilled),
            setEndOfDay(
              new Date(currentTank.reachMinimum.getTime() + TIME.DAYINMS)
            ),
            TIME.DAYINMS
          ),
          TankHistoryFilter.min,
          true,
          this.state.timezone
        );
      else
        this.tankService.getTankHistoryFromDateRange(
          siteId,
          tankId,
          {
            from: setBeginningOfDay(
              new Date(currentDate.getTime() - TIME.DAY6INMS)
            ),
            to: setEndOfDay(currentDate)
          },
          TankHistoryFilter.last7days,
          true,
          this.state.timezone
        );
  }

  tabItemClick(siteChannelId: number, tabItem: string) {
    this.router.navigateToRoute(
      routeNames.sitedetailsTanksDetails,
      { siteChannelId, tab: tabItem },
      { replace: true }
    );
  }

  tankHistoryCustomDateRangeChanged([from, to]: Date[]) {
    if (isNone(from) || isNone(to)) return;

    // assigning timestamp to outer boundaries for from and to dates.
    setBeginningOfDay(from);
    setEndOfDay(to);

    this.dispatch(tankHistoryDateRangeChanged({ from, to }));
  }

  tankHistoryCanShowReachMin(
    reachMinimum: Date | string | undefined,
    lastFilled: Date | string | undefined
  ) {
    return (
      reachMinimum &&
      lastFilled &&
      ensureDate(reachMinimum) > ensureDate(lastFilled)
    );
  }

  tankReachMinIsOverOneYearIntoTheFuture(reachMinimum: Date | string | undefined) {
    const aYearFromNow  = new Date();
    aYearFromNow.setFullYear(aYearFromNow .getFullYear() + 1)
    return (
      reachMinimum &&
      ensureDate(reachMinimum) > ensureDate(aYearFromNow)
    );
  }

  tankHistoryCanShowReachEmpty(
    reachEmpty: Date | string | undefined,
    lastFilled: Date | string | undefined
  ) {
    return (
      reachEmpty &&
      lastFilled &&
      ensureDate(reachEmpty) > ensureDate(lastFilled)
    );
  }

  tankHistoryFilterChanged(filter: TankHistoryFilter) {
    const { siteId, tankDetails } = this.state;
    if (isNone(tankDetails)) return;
    const { tankId } = tankDetails;
    if (!tankId || !siteId) return;
    const { tankHistoryCustomDateRange, tankChannel } = tankDetails;

    if (!tankChannel || !tankChannel.tankDetails) return;

    const { reachMinimum, reachEmpty, lastFilled } = tankChannel.tankDetails;

    const currentDate = new Date();
    let range: IDateRange = {
      from: tankHistoryCustomDateRange.from,
      to: tankHistoryCustomDateRange.to
    };

    switch (filter) {
      case TankHistoryFilter.last30days:
        range = {
          from: new Date(currentDate.getTime() - TIME.DAY29INMS),
          to: currentDate
        };
        setBeginningOfDay(range.from);
        setEndOfDay(range.to);
        break;      
      case TankHistoryFilter.last7days:
        range = {
          from: new Date(currentDate.getTime() - TIME.DAY6INMS),
          to: currentDate
        };
        setBeginningOfDay(range.from);
        setEndOfDay(range.to);
        break;
      case TankHistoryFilter.min:
        if (!reachMinimum || !lastFilled) return;
        range = modifyDateRange(
          setBeginningOfDay(ensureDate(lastFilled)),
          setEndOfDay(ensureDate(reachMinimum)),
          TIME.DAYINMS
        );
        break;
      case TankHistoryFilter.empty:
        if (!reachEmpty || !lastFilled) return;
        range = modifyDateRange(
          setBeginningOfDay(ensureDate(lastFilled)),
          setEndOfDay(ensureDate(reachEmpty)),
          TIME.DAYINMS
        );
        break;
    }

    this.tankService.getTankHistoryFromDateRange(
      siteId,
      tankId,
      range,
      filter,
      true,
      this.state.timezone
    );
  }

  editTankInformation() {
    if (
      isNone(this.state.tankDetails) ||
      isNone(this.state.tankDetails.tankChannel)
    )
      return;
    this.dispatch(
      markTankInformationForEdit(this.state.tankDetails.tankChannel)
    );
  }

  toggleCustomHistoryFilter() {
    this.dispatch(toggleCustomHistoryFilter());
  }

  cancelTankEdit() {
    this.dispatch(cancelTankInformationForEdit());
  }

  public toggleExpandMenuOnEntity(entity: menuIsOpenForEntity) {
    this.dispatch(toggleExpandMenuOnEntity(entity));
  }

  public changePropertyOnTankDetails(
    property: keyof ISiteChannel,
    value: string | number
  ) {
    if (
      isNone(this.state.tankDetails) ||
      isNone(this.state.tankDetails.tankChannel)
    )
      return;
    if (this.state.tankDetails.tankChannel)
      this.dispatch(editSiteChannelTankDetailsProperty({ property, value }));
  }

  public changePropertyOnSiteChannel(
    property: keyof ISiteChannel,
    value: string | number
  ) {
    if (
      isNone(this.state.tankDetails) ||
      isNone(this.state.tankDetails.editInfo)
    )
      return;
    if (this.state.tankDetails.tankChannel)
      this.dispatch(editSiteChannelProperty({ property, value }));
  }

  async persistTankInformation() {
    const { siteId, tankDetails } = this.state;
    if (
      !siteId ||
      !tankDetails ||
      !tankDetails.editInfo ||
      !tankDetails.editInfo.tankDetails
    )
      return;

    const trimmedTankDetailsEditInfo = {...tankDetails.editInfo, alias: tankDetails.editInfo.alias.trim()}

    await this.channelService.updateSiteChannel(
      tankDetails.editInfo.siteChannelId,
      trimmedTankDetailsEditInfo
    );
    await this.revalidateAllActiveQueries();
  }

  public toggleRefillPointEditing() {
    if (!this.state || !this.state.tankDetails) return;
    this.dispatch(
      toggleRefillPointEditing(!this.state.tankDetails.editRefillPointEnabled)
    );
  }

  public refillPointSelectionCancelled() {
    this.dispatch(toggleRefillPointEditing(false));
  }

  public refillPointSelectionConfirmed(
    point: ISelectedGraphPoint
  ): Promise<any> | undefined {
    const { siteId, tankDetails } = this.state;
    if (!siteId || !tankDetails) return;

    const { tankChannel } = tankDetails;
    if (!tankChannel) return;

    return this.tankService.updateTankRefillPoint(
      siteId,
      tankDetails.tankId,
      point.timestamp,
      point.value,
      tankChannel.siteChannelId
    );
  }

  @computedFrom("data.siteChannel.product.languageKey", "data.siteChannel.product.name")
  get translatedProductName() : string | undefined {
    if (this.data?.siteChannel?.product?.languageKey) {
      return this.i18n.tr(this.data?.siteChannel?.product?.languageKey)
    }
    
    return this.data?.siteChannel?.product?.name ?? undefined;
  }
  
  getTrendTitle(
    tankDetails: ISiteDetailsTankDetails | undefined,
    productname: string | undefined
  ) {
    if (isNone(tankDetails) || isNone(tankDetails.tankChannel))
      return this.i18n.tr('UI_SiteDetails_Tanks_TankDetails_TankHistory');
    return (
      tankDetails.tankChannel.alias + (productname ? ` - ${productname}` : '')
    );
  }
}

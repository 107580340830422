import './tankrefill.css';
import { customElement, bindable } from 'aurelia-framework';

@customElement('tank-refill')
export class TankRefill {
  @bindable() siteChannelId: number;
  @bindable() tankId: number;
  @bindable() siteId: number;

  processStep: 'view' | 'selectusers' | 'done';
  bind() {
    this.processStep = 'view';
  }
}

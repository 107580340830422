import './tankrefillcontacts.css';
import { GraphQLBaseViewModel } from '../../../../../../../common/GraphQLBaseViewModel';
import { getLogger } from 'aurelia-logging';
import {
  customElement,
  bindable,
  containerless,
  autoinject
} from 'aurelia-framework';
import gql from 'graphql-tag';
import {
  TankRefillContactsQuery,
  TankRefillContactsQueryVariables
} from '../../../../../../../../../custom_typings/graphql';
import { TankService } from '../../../../../../../../services';
import { I18N } from 'aurelia-i18n';
import {
  ensureNumber,
  isNone,
  validateEmail
} from '../../../../../../../../utility';

@autoinject()
@containerless()
@customElement('tank-refill-contacts')
export class TankRefillContacts extends GraphQLBaseViewModel<
  void,
  TankRefillContactsQuery,
  TankRefillContactsQueryVariables
> {
  @bindable() tankId: number;
  @bindable() siteId: number;
  @bindable() processCancelled: Function;
  @bindable() processDone: Function;

  selectedContacts: number[] = [];

  constructor(private tankService: TankService, private i18n: I18N) {
    super(getLogger(TankRefillContacts.name));
  }

  bind() {
    this.orderTankRefillError = undefined;
    this.orderTankRefillProcessing = false;
    this.selectedContacts = [];
    this.variables = {
      siteId: this.siteId
    };
  }

  toggleContactSelectForTankRefill(contactId: number) {
    const existsInSelectedContacts = this.selectedContacts.includes(contactId);
    if (existsInSelectedContacts)
      this.selectedContacts = this.selectedContacts.filter(
        c => c !== contactId
      );
    else this.selectedContacts = [...this.selectedContacts, contactId];
  }

  orderTankRefillProcessing: boolean;
  orderTankRefillError: string | undefined;

  orderTankRefill(siteId: number, tankId: number, contactIds: string[]) {
    this.orderTankRefillError = undefined;
    this.orderTankRefillProcessing = true;
    this.tankService
      .orderTankRefill(
        siteId,
        tankId,
        contactIds.map(ensureNumber),
        this.i18n.getLocale()
      )
      .then(() => {
        this.orderTankRefillProcessing = false;
        this.processDone();
      })
      .catch(error => {
        this.orderTankRefillProcessing = false;
        this.orderTankRefillError = error;
      });
  }

  isValidEmail(maybeEmail: string | undefined) {
    return !isNone(maybeEmail) && validateEmail(maybeEmail);
  }

  query = gql`
    fragment TankRefillContactsFragment on Contact {
      avatarUrl
      telephoneNumber
      email
      name
      userId
      contactId
    }
    query TankRefillContactsQuery($siteId: Int!) {
      site(siteId: $siteId) {
        siteId
        orderContact {
          ...TankRefillContactsFragment
        }
        salesContact {
          ...TankRefillContactsFragment
        }
      }
    }
  `;
}

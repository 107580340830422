import {
  customElement,
  containerless,
  bindable,
  autoinject,
  computedFrom
} from 'aurelia-framework';
import { GraphQLBaseViewModel } from '../../../../../../../common/GraphQLBaseViewModel';
import './tankrefillview.css';
import { getLogger } from 'aurelia-logging';
import { IHistoryItem, IHyperlink } from '../../../../../../../../interfaces';
import gql from 'graphql-tag';
import {
  TankRefillListQuery,
  TankRefillListQueryVariables,
  TankRefillListQuery_siteChannel_tankDetails_plannedTankRefills,
  TankRefillListQuery_siteChannel_tankDetails_tankRefills,
  TankRefillListQuery_siteChannel_tankDetails_tankOrders,
  TankRefillListQuery_siteChannel_tankDetails_tankRefills_attachments
} from '../../../../../../../../../custom_typings/graphql';
import { convertBytesToHumanReadable, isSomething } from '../../../../../../../../utility';
import { removeNoneFromArray } from '../../../../../../../../utility';
import { BlobService } from '../../../../../../../../services';
import { showPdfViewer } from '../../../../../../../../utility/pdfHelpers';

const mapPlannedTankRefillToHistoryItem = (
  plannedTank: TankRefillListQuery_siteChannel_tankDetails_plannedTankRefills
): IHistoryItem => ({
  icon: 'fa-calendar-o',
  metaInformation: `Vehicle ${plannedTank.vehicle}`,
  text: removeNoneFromArray([
    `Planned refill`,
    plannedTank.reference ? `Reference ${plannedTank.reference}` : undefined
  ]),
  timeStamp: plannedTank.timeStamp,
  class: 'color-green'
});

const mapOrderToHistoryItem = (
  order: TankRefillListQuery_siteChannel_tankDetails_tankOrders
): IHistoryItem => ({
  timeStamp: order.timeStamp,
  icon: `fa-envelope-o`,
  metaInformation: `${order.orderedByUser.name}`,
  text: [`Refill order: ${order.quantity}`]
});

interface ITankRefillListState {
  historyItems: IHistoryItem[];
  timezone: string | undefined;
}

@autoinject()
@containerless()
@customElement('tank-refill-view')
export class TankRefillList extends GraphQLBaseViewModel<
  ITankRefillListState,
  TankRefillListQuery,
  TankRefillListQueryVariables
> {
  @bindable() siteChannelId: number;
  @bindable() startOrder: Function;

  pdfLoaded: boolean;

  bind() {
    if(!this.siteChannelId) return;
    this.variables = {
      siteChannelId: this.siteChannelId
    };
  }

  siteChannelIdChanged() {
    this.variables = {
      siteChannelId: this.siteChannelId
    };
  }

  downloadImage(
    attachment: TankRefillListQuery_siteChannel_tankDetails_tankRefills_attachments
  ) {
    if (!attachment.attachmentUrl || !attachment.attachmentName) return;
    return this.blobService.downloadFileFromUrl(
      attachment.attachmentUrl,
      attachment.attachmentName
    );
  }

  showModalForRefill:
    | TankRefillListQuery_siteChannel_tankDetails_tankRefills_attachments
    | undefined;

  query = gql`
    query TankRefillListQuery($siteChannelId: Int!) {
      siteChannel(siteChannelId: $siteChannelId) {
        tankDetails {
          tankRefills {
            amountFilled
            reference
            refillId
            refillStart
            timeStamp
            vehicle
            attachments  {
              attachmentUrl,
              attachmentName,
              attachmentSize
            }
          }
          plannedTankRefills {
            plannedTankRefillId
            driverName
            plannedAt
            reference
            timeStamp
            vehicle
          }
          tankOrders {
            orderedAt
            orderedByUser {
              name
              userId
            }
            quantity
            tankChannelId
            tankOrderId
            timeStamp
          }
        }
      }
    }
  `;

  constructor(private blobService: BlobService) {
    super(getLogger(TankRefillList.name));
  }

  mapRefillHistoryToHistoryItem = (
    refillHistory: TankRefillListQuery_siteChannel_tankDetails_tankRefills
  ): IHistoryItem => ({
    timeStamp: refillHistory.timeStamp,
    icon: 'fa-truck',
    metaInformation: isSomething(refillHistory.vehicle) ? `Vehicle: ${refillHistory.vehicle}` : 'Refill',
    text: [
      `Filled ${refillHistory.amountFilled}`,
      `Reference: ${refillHistory.reference}`
    ],
    externalLinks: !refillHistory.attachments
      ? undefined
      : refillHistory.attachments.filter(attachment => attachment.attachmentUrl).map<IHyperlink>((attachment) => ({         
          text: this.getAttachmentName(attachment),
          action: async () => {
            this.showModalForRefill = attachment;
            if (this.showModalForRefill && this.showModalForRefill.attachmentUrl && this.showModalForRefill.attachmentUrl.indexOf('.pdf') >= 0)
            {
              //Load PDF viewer
              this.pdfLoaded = false;
              await showPdfViewer('pdf-viewer', this.showModalForRefill.attachmentUrl);
              this.pdfLoaded = true;
            }
          }
        })
    )
  });
  
  getAttachmentName(attachment: TankRefillListQuery_siteChannel_tankDetails_tankRefills_attachments) {
    let attachmentName = '';

    if (attachment.attachmentName?.startsWith('BL_'))
      attachmentName = 'Delivery note';
    else if (attachment.attachmentName?.startsWith('TI_'))
      attachmentName = 'HESQ report';      
    else if (attachment.attachmentName?.startsWith('SCAN_'))
      attachmentName = 'Delivery ticket';      
    else
      attachmentName = 'Attachment';      

    //Append file size if we have it
    if (attachment?.attachmentSize && attachment?.attachmentSize > 0) {
      attachmentName += ' (' + convertBytesToHumanReadable(attachment?.attachmentSize) + ')';
    }

    return attachmentName;
  }

  @computedFrom('data')
  get refillsAndOrders() {
    if (
      !this.data ||
      !this.data.siteChannel ||
      !this.data.siteChannel.tankDetails
    )
      return [];
    const {
      plannedTankRefills,
      tankRefills,
      tankOrders
    } = this.data.siteChannel.tankDetails;
    if (!plannedTankRefills || !tankRefills || !tankOrders) return [];

    return [
      ...tankRefills.map(this.mapRefillHistoryToHistoryItem),
      ...tankOrders.map(mapOrderToHistoryItem),
      ...plannedTankRefills.map(mapPlannedTankRefillToHistoryItem)
    ].sort((k, l) => (k.timeStamp < l.timeStamp ? 1 : -1));
  }
}

import { customElement, containerless, bindable } from 'aurelia-framework';
import { GraphQLBaseViewModel } from '../../../../../../../common/GraphQLBaseViewModel';
import './tankrefillsuccess.css';
import { getLogger } from 'aurelia-logging';

@containerless()
@customElement('tank-refill-success')
export class TankRefillSuccess extends GraphQLBaseViewModel<void, void, void> {
  @bindable() acknowledged: Function;
  constructor() {
    super(getLogger(TankRefillSuccess.name));
  }
}
